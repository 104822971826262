import { Backdrop, styled } from "@mui/material"
import tinycolor from "tinycolor2"
import MTvLogo from "@logos/MTV_Long_Logo_White.svg"
import NewButton from "../atoms/NewButton"
import { css } from "styled-components"
import React from "react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles"
import getTheme from "@/theme"

interface OopsOverlayProps {
	error: Error
	info: React.ErrorInfo
	clearError: () => void
}

export const OopsOverlay: React.ComponentType<OopsOverlayProps> = ({ error }) => {
	const theme = getTheme(false)
	const muiTheme = createTheme(theme)

	return (
		<MuiThemeProvider theme={muiTheme}>
			<ThemeProvider theme={theme}>
				<BlurredOverlay open={true} isNotFound={error.name === "Oops!"}>
					<LogoContainer>
						<img src={MTvLogo} alt="Eventric Logo" width="360.32px" />
					</LogoContainer>
					<TextContainer>
						<>
							<h1>{error.name}</h1>
							<h2>{error.message}</h2>
						</>
					</TextContainer>
					<ButtonContainer>
						<NewButton
							buttonType="Filled"
							text="Eventric Home"
							buttonStyles={css`
								height: 68px;
								width: 239px;
							`}
							textStyles={css`
								font: 400 22px/28px Roboto-Regular;
							`}
							onClick={() => window.open("https://www.eventric.com", "_blank")}
						/>
						<NewButton
							buttonType="Filled"
							text="Contact Support"
							buttonStyles={css`
								height: 68px;
								width: 239px;
								background-color: #f4f3f7; //useTheme
							`}
							textStyles={css`
								font: 400 22px/28px Roboto-Regular;
								color: #0060a8;
							`}
							onClick={() => window.open("https://support.eventric.com", "_blank")}
						/>
					</ButtonContainer>
				</BlurredOverlay>
			</ThemeProvider>
		</MuiThemeProvider>
	)
}

const BlurredOverlay = styled(Backdrop, {
	shouldForwardProp: prop => prop !== "isNotFound",
})<{ isNotFound: boolean }>`
	&.MuiBackdrop-root {
		background-color: ${tinycolor("#001C38").setAlpha(0.6).toHex8String()};
		backdrop-filter: blur(4px);
		z-index: 10000;
		padding: 240px;
		flex-direction: column;
		align-items: start;
		gap: 48px;
	}
`

const LogoContainer = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	padding: 48px;
`

const TextContainer = styled("div")`
	color: white;

	& > h1 {
		text-shadow: 0px 1px 3px 1px black, 0px 1px 2px 0px black;
		font: 600 96px/96px Roboto-Bold;
		letter-spacing: -0.25px;
		padding-bottom: 24px;
	}

	& > h2 {
		font: 400 45px/52px Roboto-Regular;
	}
`

const ButtonContainer = styled("div")`
	display: flex;
	gap: 48px;
`
