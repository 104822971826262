import { Drawer, styled } from "@mui/material"
import LogoLight from "@logos/MTV_Logo_Black.svg"
import LogoDark from "@logos/MTV_Logo_White.svg"
import useDarkMode from "@/hooks/useDarkMode"

import { Menu } from "@mui/icons-material"
import { NavigationBar } from "./NavigationBar"
import { useNavigationContext } from "@/context/navigation"
import mediaQuery from "@/util/mediaQuery"

export const MobileHeader = () => {
	const { darkMode } = useDarkMode()
	const { mobileMenuOpen, setMobileMenuOpen } = useNavigationContext()

	return (
		<>
			<HeaderContainer isDarkMode={darkMode}>
				<img width="131.69px" src={darkMode ? LogoDark : LogoLight} alt="Master Tour Venue Logo" />
				<HamburgerIcon onClick={() => setMobileMenuOpen(prev => !prev)} />
			</HeaderContainer>
			<MobileNavigation open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
		</>
	)
}

const MobileNavigation = ({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	return (
		<StyledDrawer
			anchor={"top"}
			open={open}
			onClose={() => setOpen(false)}
			transitionDuration={250}
		>
			<NavigationBar isMobile />
			{/* <BaseContainer>
				<TitleDiv>
					<TitleSpan>{t("selectVenue")}</TitleSpan>
				</TitleDiv>
				<SelectorDiv>
					<VenueSelector zIndex={102} />
				</SelectorDiv>
			</BaseContainer>

			<Divider />

			<BaseContainer>
				<TitleDiv>
					<TitleSpan>{t("selectStage")}</TitleSpan>
				</TitleDiv>
				<SelectorDiv>
					<StageSelector zIndex={101} />
				</SelectorDiv>
			</BaseContainer>

			<Divider />
			<BaseContainer>
				<TitleDiv>
					<TitleSpan>{t("mainMenu")}</TitleSpan>
				</TitleDiv>
				<MenuItem
					{...getLinkProps("/staff-crew")}
					IconComponent={StaffCrew}
					text={t("staffCrew")}
				/>
				<MenuItem
					{...getLinkProps("/production")}
					IconComponent={NewProduction}
					text={t("production")}
				/>
				<MenuItem
					{...getLinkProps("/venueStage")}
					IconComponent={VenueStage}
					text={t("venueStage")}
				/>
				<MenuItem {...getLinkProps("/history")} IconComponent={History} text={t("history")} />
				<MenuItem
					onClick={
						selectedVenue?.id === undefined || selectedStage?.id === undefined
							? undefined
							: () =>
									window.open(
										`venue/${selectedVenue.id}/${selectedStage.id}`,
										"_blank",
										`rel="noopener noreferrer"`,
									)
					}
					IconComponent={TechPack}
					text={t("techPack")}
				/>
			</BaseContainer> */}
		</StyledDrawer>
	)
}

const StyledDrawer = styled(Drawer)`
	&.MuiDrawer-root {
		z-index: 100;
		display: none;

		${mediaQuery("md")`
			display: block;
		`}
	}

	& .MuiDrawer-paper {
		height: 100%;
		z-index: 100;
		padding-top: 60px;
		background-color: ${({ theme }) => theme.colorPalette.surface.container.value};

		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
	}
`

const HamburgerIcon = styled(Menu)`
	&.MuiSvgIcon-root {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		cursor: pointer;
		height: 100%;
	}
`

const HeaderContainer = styled("div", {
	shouldForwardProp: prop => prop !== "isDarkMode",
})<{ isDarkMode: boolean }>`
	display: none;
	justify-content: space-between;
	align-items: center;
	position: fixed;

	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	padding: 11px 16px;
	z-index: 101;

	background-color: ${({ theme, isDarkMode }) =>
		isDarkMode
			? theme.colorPalette.surface.container.value
			: theme.colorPalette.surface.container.lowest};

	border-bottom: 1px solid
		${({ theme, isDarkMode }) =>
			isDarkMode ? theme.colorPalette.outline.value : `rgba(217, 217, 217, 1)`};

	${mediaQuery("md")`
		display: flex;
	`}
`
