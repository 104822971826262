import { useAttachments } from "@/hooks/useAttachments"
import useAxios from "@/hooks/useAxios"
import useUserContext from "@/hooks/useUserContext"
import { useVenuesContext } from "@context/venues"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useConfirmCloseContext } from "./confirmClose"

const DEFAULT_PROFILE_STATE: ProfileContextStateType = {
	firstName: "",
	lastName: "",
	preferredName: "",
	phone: "",
}

export const ProfileContext = createContext<ProfileContextType>({
	state: DEFAULT_PROFILE_STATE,
	setState: () => null,
	userProfile: null,
	updateUserProfile: () => null,
	updateUserAvatar: () => null,
	userStaffEmail: "",
	canSubmit: false,
})

export const ProfileContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const [state, setState] = useState(DEFAULT_PROFILE_STATE)
	const [pageHasChanges, setPageHasChanges] = useState(false)
	const { shouldPreventClose, setShouldPreventClose } = useConfirmCloseContext()
	const { user } = useUserContext()
	const { t } = useTranslation()
	const axios = useAxios()
	const queryClient = useQueryClient()
	const { selectedStage } = useVenuesContext()

	const { data: userProfile = null } = useQuery({
		queryKey: [
			"profile",
			{
				userId: user.userId,
			},
		],
		queryFn: () =>
			axios
				.post<{ data: UserProfile }>(`/venues/v1/profile/by-user-id/${user.userId}`, {
					includeAvatar: true,
					includeRolesAndLocations: true,
				})
				.then(res => res.data.data),
		enabled: user.userId !== "",
		meta: {
			errorMessage: t("errorMessage"),
		},
	})

	const { uploadAttachment } = useAttachments("profile", userProfile?.id ?? "")

	const { data: userStaffEmail = "" } = useQuery({
		queryKey: ["staff-profile"],
		queryFn: () =>
			axios
				.get<{ data: { email: string } }>(
					`venues/v1/venues/${selectedStage?.venueId}/staff-crew/${user?.userId}`,
				)
				.then(res => res.data.data.email),
		enabled: user.userId !== "" && selectedStage !== null,
	})

	const { mutate: updateUserProfile, isPending: updatePending } = useMutation({
		mutationKey: ["updateProfile"],
		mutationFn: async () => {
			setPageHasChanges(false)
			return axios
				.patch<{ data: UserProfile }>(`venues/v1/profile/${userProfile?.id}`, {
					name: {
						firstName: state.firstName,
						lastName: state.lastName,
						preferredName: state.preferredName,
					},
					phone: state.phone,
				})
				.then(res => res.data.data)
		},
		onSuccess: () => {
			toast.success(t("updateSuccessful"))
			queryClient.invalidateQueries({
				predicate: query =>
					query.queryKey[0] === "profile" ||
					query.queryKey[0] === "staff-crew" ||
					query.queryKey[0] === "staff-crew-member" ||
					query.queryKey[0] === "production-contacts",
			})
		},
		onError: () => {
			toast.error(t("tryAgain"))
		},
	})

	const updateUserAvatar: React.ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files === null) return
		const uploadFile = e.target.files.item(0)
		if (uploadFile === null) return

		uploadAttachment({
			file: uploadFile,
			replaceExisting: true,
		}).then(() => {
			queryClient.invalidateQueries({
				predicate: query =>
					query.queryKey[0] === "profile" ||
					query.queryKey[0] === "staff-crew" ||
					query.queryKey[0] === "staff-crew-member" ||
					query.queryKey[0] === "production-contacts",
			})
		})
	}

	const requiredFieldEmpty = useMemo(() => {
		return (
			state.firstName.replaceAll(" ", "") === "" ||
			state.lastName.replaceAll(" ", "") === "" ||
			state.phone.replaceAll(" ", "") === ""
		)
	}, [state.firstName, state.lastName, state.phone])

	useEffect(() => {
		const updatePageHasChanges = () => {
			if (
				userProfile !== null &&
				!updatePending &&
				(state.firstName !== (userProfile?.name.firstName ?? "") ||
					state.lastName !== (userProfile?.name.lastName ?? "") ||
					state.preferredName !== (userProfile?.name.preferredName ?? "") ||
					state.phone !== (userProfile?.phone ?? ""))
			) {
				if (pageHasChanges === false) {
					setPageHasChanges(true)
				}
			} else {
				if (pageHasChanges === true) {
					setPageHasChanges(false)
				}
			}
		}
		let ignore = false

		setTimeout(() => {
			if (!ignore) {
				updatePageHasChanges()
			}
		}, 250)

		return () => {
			ignore = true
		}
	}, [state, userProfile, updatePending, pageHasChanges, setPageHasChanges])

	useEffect(() => {
		if (userProfile !== null) {
			setState({
				firstName: userProfile.name.firstName ?? "",
				lastName: userProfile.name.lastName ?? "",
				preferredName: userProfile.name.preferredName ?? "",
				phone: userProfile.phone ?? "",
			})
		}
	}, [userProfile, setState])

	useEffect(() => {
		if (pageHasChanges && !shouldPreventClose) {
			setShouldPreventClose(true)
		} else if (!pageHasChanges && shouldPreventClose) {
			setShouldPreventClose(false)
		}
	}, [pageHasChanges, shouldPreventClose, setShouldPreventClose])

	return (
		<ProfileContext.Provider
			value={{
				state,
				setState,
				userProfile,
				updateUserProfile,
				updateUserAvatar,
				userStaffEmail,
				canSubmit: pageHasChanges && !requiredFieldEmpty,
			}}
		>
			{children}
		</ProfileContext.Provider>
	)
}

export const useProfileContext = () => {
	return useContext(ProfileContext)
}
