import useTechPack from "../../../hooks/useTechPack"
import { FC } from "react"
import mediaQuery from "../../../util/mediaQuery"
import AttachmentRow from "./AttachmentRow"
import { useTranslation } from "react-i18next"
import KeyValuePair from "../../atoms/KeyValuePair"
import Linkify from "linkify-react"
import { styled } from "@mui/material"

const CreateTechRow: FC<TechRowParams> = ({ category, field, parentId, ...rest }) => {
	const { stage, showSubsection } = useTechPack()
	const { t } = useTranslation()

	const stageCategory: any = stage[category] || {}
	const categoryId = `${parentId}-${field.replace(/ /g, "")}`

	let value = stageCategory[field] || ""

	if (!showSubsection(category, [field])) {
		return null
	}

	if (typeof value === "object") {
		value = Object.fromEntries(Object.entries(value).filter(([_, v]) => !!v))
	}

	return (
		<KeyValueContainer {...rest} id={categoryId}>
			<KeyTextContainer data-cy={`techPack-section_label-${field}`}>
				<Text>{t(field)}</Text>
			</KeyTextContainer>
			<Linkify
				as="div"
				options={{
					target: "_blank",
					rel: "noreferrer",
				}}
			>
				{typeof value === "string" && <ValueText>{value}</ValueText>}
				{typeof value === "object" && (!!value.width || !!value.depth || !!value.height) && (
					<KeyPairContainer>
						{!!value.width && (
							<KeyValuePair
								key={`width-${value.width}`}
								keyName={t("width")}
								value={value.width}
								width="8rem"
							/>
						)}
						{!!value.depth && (
							<KeyValuePair
								key={`depth-${value.depth}`}
								keyName={t("depth")}
								value={value.depth}
								width="8rem"
							/>
						)}
						{!!value.height && (
							<KeyValuePair
								key={`height-${value.height}`}
								keyName={t("height")}
								value={value.height}
								width="8rem"
							/>
						)}
					</KeyPairContainer>
				)}
				{typeof value === "object" && !value.width && !value.depth && !value.height && (
					<KeyPairContainer>
						{Object.keys(value).map((key: string) => (
							<KeyValuePair
								key={`${key}-${value[key]}`}
								keyName={t(key)}
								value={value[key]}
								width="4rem"
							/>
						))}
					</KeyPairContainer>
				)}
				<AttachmentRow category={category} field={field} />
			</Linkify>
		</KeyValueContainer>
	)
}

const KeyValueContainer = styled("div")`
	margin-top: 30px;
	${mediaQuery("md")`
		margin-top: 15px;
	`}

	& a {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`
const KeyPairContainer = styled("div")``
const KeyTextContainer = styled("div")``

const Text = styled("div")`
	font-family: "Signika-Bold" !important;
	font-size: 18px;
	margin-bottom: 15px;
	line-height: 24px;

	${mediaQuery("md")`
		font-size: 14px;
		margin-bottom: 8px;
	`}
`
const ValueText = styled("div")`
	font-family: "Signika-Regular" !important;
	white-space: pre-wrap;
	font-size: 18px;
	line-height: 24px;

	${mediaQuery("md")`
		font-size: 14px;
	`}
`

export default CreateTechRow
