import styled from "styled-components"
import Info from "../../../../icons/Info"
import Contact from "../../../../icons/Contact"
import Production from "../../../../icons/Production"
import Facility from "../../../../icons/Facility"
import Equipment from "../../../../icons/Equipment"
import Logistics from "../../../../icons/Logistics"
import NavigationRow from "./NavigationRow"
import mediaQuery from "../../../../util/mediaQuery"
import useTechPackNavigationData from "../../../../hooks/useTechPackNavigationData"
import { useTranslation } from "react-i18next"
import Crew from "../../../../icons/Crew"
import LightDarkModeToggle from "./LightDarkModeToggle"
import useDarkMode from "../../../../hooks/useDarkMode"
import useTechPackNavigationHandler from "../../../../hooks/useTechPackNavigationHandler"
import LogoLight from "@logos/MTV_Logo_Black.svg"
import LogoDark from "@logos/MTV_Logo_White.svg"

const Navigation = () => {
	const { showFacilities, showPersonnel, showProduction, keysToShow } = useTechPackNavigationData()
	const { t } = useTranslation()
	const handleItemClick = useTechPackNavigationHandler()

	const { darkMode } = useDarkMode()
	return (
		<Main data-cy="techPack-leftNav_Container">
			<LogoContainer>
				<a
					href="https://www.eventric.com/"
					rel="noreferrer"
					target="_blank"
					data-cy="techPack-header_EventricLogo"
				>
					<Logo src={darkMode ? LogoDark : LogoLight} alt="Master Tour Venue Logo" />
				</a>
			</LogoContainer>
			<NavigationRow
				data-cy="techPack-leftNav_venueInfo"
				title="venueInfo"
				icon={Info}
				onClick={() => handleItemClick("venueInfo")}
			>
				<></>
			</NavigationRow>
			{showPersonnel && (
				<NavigationRow
					data-cy="techPack-leftNav_Contacts"
					title="contact_plural"
					icon={Contact}
					onClick={() => handleItemClick("contacts")}
				>
					<></>
				</NavigationRow>
			)}
			{showProduction && (
				<NavigationRow data-cy="techPack-leftNav_Production" title="production" icon={Production}>
					{Object.keys(keysToShow.production).map((category: string) => {
						// @ts-ignore
						if (keysToShow.production[category]?.length) {
							return (
								<CategoryContainer key={category}>
									<NavigationCategory
										data-cy={`techPack-leftNav_label-${category}`}
										onClick={() => handleItemClick(`production-${category}`)}
									>
										{t(category)}
									</NavigationCategory>
									<SubcategoryContainer>
										{/* @ts-ignore */}
										{keysToShow.production[category]?.map((item: any) => (
											<NavigationSubcategory
												data-cy={`techPack-leftNav_label-${item}`}
												key={item}
												onClick={() => handleItemClick(`production-${category}-${item}`)}
											>
												{t(item)}
											</NavigationSubcategory>
										))}
									</SubcategoryContainer>
								</CategoryContainer>
							)
						} else return null
					})}
				</NavigationRow>
			)}
			{showFacilities && (
				<NavigationRow
					data-cy="techPack-leftNav_Facilities"
					title="facility_plural"
					icon={Facility}
				>
					{Object.keys(keysToShow.facilities).map((category: string) => {
						// @ts-ignore
						if (keysToShow.facilities[category]?.length) {
							return (
								<CategoryContainer key={category}>
									<NavigationCategory
										data-cy={`techPack-leftNav_label-${category}`}
										onClick={() => handleItemClick(`facilities-${category}`)}
									>
										{t(category)}
									</NavigationCategory>
									<SubcategoryContainer>
										{/* @ts-ignore */}
										{keysToShow.facilities[category]?.map((item: any) => (
											<NavigationSubcategory
												data-cy={`techPack-leftNav_label-${item}`}
												key={item}
												onClick={() => handleItemClick(`facilities-${category}-${item}`)}
											>
												{t(item)}
											</NavigationSubcategory>
										))}
									</SubcategoryContainer>
								</CategoryContainer>
							)
						} else return null
					})}
				</NavigationRow>
			)}
			{keysToShow?.equipment?.length ? (
				<NavigationRow data-cy="techPack-leftNav_Equipment" title="equipment" icon={Equipment}>
					<CategoryContainer>
						{keysToShow.equipment.map(key => (
							<NavigationCategory
								data-cy={`techPack-leftNav_label-${key}`}
								key={key}
								onClick={() => handleItemClick(`equipment-${key}`)}
							>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			{keysToShow?.logistics?.length ? (
				<NavigationRow data-cy="techPack-leftNav_Logistics" title="logistics" icon={Logistics}>
					<CategoryContainer>
						{keysToShow.logistics.map(key => (
							<NavigationCategory
								data-cy={`techPack-leftNav_label-${key}`}
								key={key}
								onClick={() => handleItemClick(`logistics-${key}`)}
							>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			{keysToShow?.localCrew?.length ? (
				<NavigationRow data-cy="techPack-leftNav_Local Crew" title="localCrew" icon={Crew}>
					<CategoryContainer>
						{keysToShow.localCrew.map(key => (
							<NavigationCategory
								data-cy={`techPack-leftNav_label-${key}`}
								key={key}
								onClick={() => handleItemClick(`localCrew-${key}`)}
							>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			<LightDarkModeToggle />
		</Main>
	)
}

const Main = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border: ${({ theme }) => `1px solid ${theme.techPackBorder}`};
	border-radius: 10px;
	padding: 30px;
	min-width: 360px;
	height: 100%;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);

	${mediaQuery("md")`
		display: none;
	`}

	${mediaQuery("lg")`
		padding: 20px;
		min-width: 250px;
	`}

	* > p {
		margin: 0;
	}
`
const Logo = styled.img`
	width: 211.44px;
	max-width: 211.44px;
	height: auto;
	object-fit: contain;

	${mediaQuery("lg")`
		max-width: 190px;
	`}
`
const LogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-bottom: 35px;
`
const CategoryContainer = styled.ul`
	font-size: 18px;
	padding-left: 44px;

	${mediaQuery("lg")`
		font-size: 14px;
	`}
`
const NavigationCategory = styled.li`
	list-style-type: circle;
	margin-left: 16px;
	padding-left: 6px;
	padding-top: 8px;
	padding-bottom: 10px;
	${mediaQuery("lg")`
		margin-left: 14px;
	`}
`
const SubcategoryContainer = styled.li`
	list-style-type: none;
	border-left: 2px solid ${({ theme }) => theme.techPackText};
`
const NavigationSubcategory = styled.div`
	padding-top: 8px;
	padding-left: 20px;
	margin-bottom: 10px;
	font-family: "Signika-Regular" !important;
`

export default Navigation
