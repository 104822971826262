/** Used to format user input into a string that matches the regex above.
 *  Truncate decimal to 7 digits of precision, ref PR VWI-240 */
export const formatLatLong = (latLong: number | string) => {
	let parseValue: number
	if (typeof latLong === "string") {
		parseValue = Number.parseFloat(latLong)
	} else {
		parseValue = latLong
	}

	return isNaN(parseValue) ? "" : (parseValue.toFixed(7) || "").toString()
}
/** Ensure latlong matches MySQL column constraints */
export const isValidLatLong = (latLong: number, isLatitude: boolean) => {
	const parsedLatLong = formatLatLong(latLong)

	return isLatitude
		? /^^[-]?([0-8]?[0-9])\.(\d){0,7}$/.test(parsedLatLong)
		: /^[-]?(((1[0-7][0-9])|([0-9]?[0-9]))\.(\d){0,7}?)$/.test(parsedLatLong)
}

/** Makes sure unnecessary updates regarding latlong aren't made */
export const latLongMatch = (original: number | string, updated: number | string) => {
	const formattedOriginal = formatLatLong(original).replace(/0*$/g, "")
	const formattedUpdated = formatLatLong(updated).replace(/0*$/g, "")

	return formattedOriginal === formattedUpdated
}

/** Returns a string to be provided for a static google map/google maps link */
export const getVenueLocation = (venue: Venue, preferAddress: boolean = false) => {
	if (!preferAddress && (venue.latitude !== 0 || venue.longitude !== 0)) {
		return `${formatLatLong(venue.latitude).replace(/0*$/g, "")},${formatLatLong(
			venue.longitude,
		).replace(/0*$/g, "")}`
	}

	return [venue.addressLine1, venue.city, venue.state].filter(val => val !== "").join(",")
}

/** Builds a static map link for the google maps api to display a venue location */
export const getStaticGoogleMapsLink = (
	venue: Venue,
	{
		darkMode,
		markerHex,
	}: {
		darkMode?: boolean
		markerHex?: string
	} = {},
) => {
	const venueLocation = encodeURIComponent(getVenueLocation(venue))
	const markerText = encodeURIComponent(venue.name.split("")[0] || "")
	return `https://maps.googleapis.com/maps/api/staticmap?center=${venueLocation}&markers=${
		markerHex !== undefined ? `color:${encodeURIComponent(markerHex.replace("#", "0x"))}%7C` : ""
	}${markerText === "" ? "" : `label:${markerText}%7C`}${venueLocation}&size=640x270&zoom=14${
		darkMode ? "&style=feature:all|invert_lightness:true" : ""
	}`
}

/** Builds an external link to google maps for a venue, prioritizing name and address with coordinates as a fallback  */
export const getExternalGoogleMapsLink = (venue: Venue) => {
	const address = encodeURIComponent(getVenueLocation(venue, true))
	const latLong = encodeURIComponent(getVenueLocation(venue))

	return `https://maps.google.com/maps?q=${encodeURIComponent(venue.name)}${
		address !== "" ? `%20${address}` : ""
	}${latLong !== address ? `%20${latLong}` : ""}`
}
