import FormField from "@/components/atoms/FormField"
import NewButton from "@/components/atoms/NewButton"
import { STAGE_SUBCATEGORY_KEYS } from "@/util/constants/STAGE_SUBCATEGORY_KEYS"
import { AttachFile } from "@mui/icons-material"
import { Divider, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { css } from "styled-components"
import { AttachmentsPreview } from "./AttachmentsPreview"
import { getCategory } from "@/util/fieldUtils"
import React, { useCallback, useEffect, useState } from "react"
import useDebounce from "@/hooks/useDebounce"
import useProductionAttachments from "@/hooks/useProductionAttachments"
import { getObjectValues } from "@/util/typedObjectMapping"
import { useVenuesContext } from "@/context/venues"
import { useProductionContext } from "@/context/production"

type SectionState = Partial<
	Omit<Record<StageField, string>, "dimensions" | "proscenium"> &
		Record<"dimensions" | "proscenium", DimensionProscenium>
>

interface ProductionSectionProps {
	stageSubcategory: StageSubcategory
}

export const ProductionSection = ({ stageSubcategory }: ProductionSectionProps) => {
	const { t } = useTranslation()
	const { selectedStage } = useVenuesContext()
	const { setState: setAttachmentState } = useProductionAttachments()
	const {
		state: { stage },
		setState,
	} = useProductionContext()

	const [currentStageId, setCurrentStageId] = useState(stage.id)

	const stageCategory = getCategory(stageSubcategory)

	const [sectionState, setSectionState] = useState<SectionState>(stage[stageCategory] ?? {})
	const [updatedValues, setUpdatedValues] = useState<StageField[]>([])

	const updateState = useCallback(
		(
			categoryField: Exclude<StageField, "dimensions" | "proscenium">,
			e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			setSectionState(prev => ({
				...prev,
				[categoryField]: e.target.value,
			}))
			setUpdatedValues(prev => [...prev, categoryField])
		},
		[setSectionState, setUpdatedValues],
	)

	const updateDimensionsState = useCallback(
		(
			categoryField: "dimensions" | "proscenium",
			dimension: "width" | "depth" | "height",
			e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			setSectionState(prev => {
				return {
					...prev,
					[categoryField]: {
						...(prev[categoryField] || {}),
						[dimension]: e.target.value,
					},
				}
			})
			setUpdatedValues(prev => [...prev, categoryField])
		},
		[setSectionState, setUpdatedValues],
	)

	const updateProductionState = useCallback(
		(newSectionState: SectionState) => {
			if (updatedValues.length > 0) {
				setState(prev => {
					const previousStageValues = { ...(prev.stage[stageCategory] || {}) }

					updatedValues.forEach(keyVal => {
						if (keyVal === "dimensions" || keyVal === "proscenium") {
							const newValue = {
								...newSectionState[keyVal],
							}
							const oldShallowValue = {
								...(previousStageValues[keyVal] || {}),
							}
							const oldDeepValue = (selectedStage?.[stageCategory] || {})[keyVal]

							if (
								newValue === undefined ||
								getObjectValues(newValue).every(
									val => val === "" || val === undefined || val === null,
								)
							) {
								if (oldDeepValue === undefined) {
									if (previousStageValues[keyVal] !== undefined) {
										delete previousStageValues[keyVal]
									}
								} else if (
									getObjectValues(oldDeepValue).every(
										val => val === "" || val === null || val === undefined,
									)
								) {
									previousStageValues[keyVal] = { ...oldDeepValue }
								} else {
									previousStageValues[keyVal] = {
										width: null,
										depth: null,
										height: null,
									}
								}

								return
							}

							if (
								Object.entries(newValue).find(
									([key, val]) => (val || "") !== (oldShallowValue[key] || ""),
								)
							) {
								previousStageValues[keyVal] = {
									width: newValue.width || null,
									depth: newValue.depth || null,
									height: newValue.height || null,
								}
							}
						} else {
							const newValue = newSectionState[keyVal]
							const oldShallowValue = previousStageValues[keyVal]
							const oldDeepValue = (selectedStage?.[stageCategory] || {})[keyVal]

							if (newValue !== "") {
								if (newValue !== oldShallowValue) {
									previousStageValues[keyVal] = newValue
								}

								return
							}

							if (oldDeepValue === undefined) {
								if (previousStageValues[keyVal] !== undefined) {
									delete previousStageValues[keyVal]
								}
								return
							}

							if (oldDeepValue === "") {
								previousStageValues[keyVal] = ""
							}

							previousStageValues[keyVal] = null
						}
					}, {})

					return {
						...prev,
						stage: {
							...prev.stage,
							[stageCategory]: {
								...previousStageValues,
							},
						},
					}
				})
			}
		},
		[stageCategory, setState, updatedValues, selectedStage],
	)

	useEffect(() => {
		if (stage.id !== currentStageId) {
			setUpdatedValues([])
			setSectionState(stage[stageCategory || {}])
			setCurrentStageId(stage.id)
		}
	}, [stage, setCurrentStageId, currentStageId, stageCategory])

	useDebounce(sectionState, updateProductionState, 500)

	return (
		<BaseContainer>
			<HeaderContainer>
				<h4>{t(stageSubcategory.split("Data")[0])}</h4>
				<NewButton
					buttonType="Outline"
					text={t("addAttachment")}
					borderColor="secondary"
					iconElement={<AttachFile />}
					buttonStyles={css`
						width: 170px;
					`}
					onClick={() =>
						setAttachmentState(prev => ({
							...prev,
							category: stageCategory,
							categorySection: stageSubcategory,
							addAttachmentModalOpen: true,
							addEditModalState: {
								...prev.addEditModalState,
							},
						}))
					}
				/>
				<small>
					{t("addStageAttachmentBlurb", {
						section: t(stageSubcategory.split("Data")[0]),
					})}
				</small>
			</HeaderContainer>
			<FieldsContainer>
				{stage !== null &&
					STAGE_SUBCATEGORY_KEYS[stageSubcategory].map(val => {
						return val === "dimensions" || val === "proscenium" ? (
							<FieldContainer key={`${stageSubcategory}-${val}`}>
								<DimProText>{t(val)}</DimProText>
								<ThreeItem>
									<FormField
										value={sectionState[val]?.width || ""}
										onChange={e => {
											updateDimensionsState(val, "width", e)
										}}
										sx={{
											flexGrow: "1",
										}}
										label={t("width")}
										fullWidth
									/>
									<FormField
										value={sectionState[val]?.depth || ""}
										onChange={e => {
											updateDimensionsState(val, "depth", e)
										}}
										sx={{
											flexGrow: "1",
										}}
										label={t("depth")}
										fullWidth
									/>
									<FormField
										value={sectionState[val]?.height || ""}
										onChange={e => {
											updateDimensionsState(val, "height", e)
										}}
										sx={{
											flexGrow: "1",
										}}
										label={t("height")}
										fullWidth
									/>
								</ThreeItem>
								{val === "proscenium" && <StyledDivider />}
							</FieldContainer>
						) : (
							<FieldContainer key={`${stageSubcategory}-${val}`}>
								<FormField
									value={sectionState[val] || ""}
									onChange={e => {
										updateState(val, e)
									}}
									multiline
									label={t(val)}
									fullWidth
									maxRows={16}
								/>
								<AttachmentsPreview category={stageCategory} field={val} />
							</FieldContainer>
						)
					})}
			</FieldsContainer>
		</BaseContainer>
	)
}

const StyledDivider = styled(Divider)`
	border-color: ${({ theme }) => theme.colorPalette.outline.variant};
	width: 100%;
	margin: 24px 0px 16px 0px;
`

const DimProText = styled("h5")`
	font-family: Signika-Medium;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: ${({ theme }) => theme.colorPalette.surface.on};
`

const ThreeItem = styled("div")`
	width: 100%;
	display: flex;
	gap: 24px;
	justify-content: start;
	align-items: center;
`

const FieldContainer = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const BaseContainer = styled("div")`
	width: 100%;
	display: flex;
	gap: 24px;
	margin-top: 24px;
	margin-bottom: 24px;
`

const HeaderContainer = styled("div")`
	width: 216px;
	gap: 24px;
	display: flex;
	flex-direction: column;
	user-select: none;
	& > small {
		color: ${({ theme }) => theme.colorPalette.secondary.value};
		font: 500 11px/16px Signika-Medium;
	}

	& > h4 {
		font: 400 24px/32px Roboto-Regular;
		color: ${({ theme }) => theme.colorPalette.surface.on};
	}
`

const FieldsContainer = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 24px;
	flex-grow: 1;
`
