import MenuItem from "../atoms/navigation/MenuItem"
import { useCallback } from "react"
import {
	History,
	Logout,
	NewProduction,
	Profile,
	StaffCrew,
	TechPack,
	VenueStage,
} from "@icons/index"
import SectionContainer from "@atoms/navigation/SectionContainer"
import DarkModeToggle from "@molecules/navigation/DarkModeToggle"
import LogoLight from "@logos/MTV_Logo_Black.svg"
import LogoDark from "@logos/MTV_Logo_White.svg"
import { useTranslation } from "react-i18next"
import VenueSelector from "@/components/molecules/navigation/VenueSelector"
import StageSelector from "@molecules/navigation/StageSelector"
import { useLocation, useNavigate } from "react-router-dom"
import useDarkMode from "@hooks/useDarkMode"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useAxios from "@hooks/useAxios"
import { toast } from "react-toastify"
import { useVenuesContext } from "@/context/venues"
import mediaQuery from "@/util/mediaQuery"
import { styled } from "@mui/material"

export const NavigationBar = ({
	isMobile = false,
}: {
	isMobile?: boolean
	closeNav?: () => void
}) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { selectedVenue, selectedStage } = useVenuesContext()
	const { darkMode } = useDarkMode()

	const getLinkProps = useCallback(
		(currPath: string) => {
			return {
				active: currPath === pathname,
				to: currPath,
			}
		},
		[pathname],
	)

	const axios = useAxios()
	const queryClient = useQueryClient()

	const { mutate: handleLogout } = useMutation({
		mutationFn: async () => {
			return axios.get("/venues/v1/logout")
		},
		onError: () => {
			toast.error(t("errorTryAgain"))
		},
		onSuccess: () => {
			localStorage.token = ""
			queryClient.invalidateQueries()
			navigate("/login")
		},
	})

	return (
		<NavbarWrapper isMobile={isMobile}>
			<NavigationBase>
				{!isMobile && (
					<LogoContainer>
						<Logo src={darkMode ? LogoDark : LogoLight} alt="Master Tour Venue Logo" />
					</LogoContainer>
				)}

				<SectionContainer title={t("selectVenue")} hideDivider={isMobile}>
					<SelectorDiv>
						<VenueSelector zIndex={101} />
					</SelectorDiv>
				</SectionContainer>

				<SectionContainer title={t("selectStage")}>
					<SelectorDiv>
						<StageSelector zIndex={100} />
					</SelectorDiv>
				</SectionContainer>

				<SectionContainer title={t("mainMenu")}>
					<MenuItem
						{...getLinkProps("/staff-crew")}
						IconComponent={StaffCrew}
						text={t("staffCrew")}
					/>
					<MenuItem
						{...getLinkProps("/production")}
						IconComponent={NewProduction}
						text={t("production")}
					/>
					<MenuItem
						{...getLinkProps("/venueStage")}
						IconComponent={VenueStage}
						text={t("venueStage")}
					/>
					<MenuItem {...getLinkProps("/history")} IconComponent={History} text={t("history")} />
					<TechLinkWrapper
						href={
							selectedStage && selectedVenue ? `venue/${selectedVenue.id}/${selectedStage.id}` : "#"
						}
						target="_blank"
						rel="noopener noreferrer"
					>
						<MenuItem onClick={() => {}} IconComponent={TechPack} text={t("techPack")} />
					</TechLinkWrapper>
				</SectionContainer>
				<SectionContainer>
					<MenuItem {...getLinkProps("/profile")} IconComponent={Profile} text={t("profile")} />
					<MenuItem IconComponent={Logout} text={t("logout")} onClick={handleLogout} />
				</SectionContainer>
				<DarkModeToggle />
				<NavbarFooter>
					<p>
						© 2024 Eventric •{" "}
						<a href="http://support.eventric.com" target="_blank" rel="noreferrer">
							support.eventric.com
						</a>
					</p>
				</NavbarFooter>
			</NavigationBase>
		</NavbarWrapper>
	)
}

const TechLinkWrapper = styled("a")`
	text-decoration: none;
`

const NavbarFooter = styled("div")`
	width: 100%;
	height: 20px;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 16px;

	& > p {
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		font-family: Signika-Medium;

		color: ${({ theme }) => theme.colorPalette.surface.onVariant};

		& a {
			font-family: inherit;
			color: inherit;
		}
	}

	${mediaQuery("md")`
		margin-top: 32px;
	`}
`

const SelectorDiv = styled("div")`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`

const LogoContainer = styled("div")`
	width: 288px;
	padding: 16px 22px 0px 22px;
	margin-bottom: -14px;
`

const Logo = styled("img")`
	width: 239.5px;
	height: auto;
`

const NavigationBase = styled("div")`
	width: 288px;
	height: 988px;
	margin: 0px;

	background-color: ${({ theme }) => theme.colorPalette.surface.container.value};

	gap: 0px;
	border: 0px 1px 0px 0px;
	display: flex;
	flex-direction: column;

	${mediaQuery("md")`
		position: static;
		width: 100%;
		height: auto;
		justify-content: start;
		align-items: center;
		background: none;
	`}
`

const NavbarWrapper = styled("div", {
	shouldForwardProp: prop => prop !== "isMobile",
})<{ isMobile: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	overflow-y: scroll;
	background: ${({ theme }) => theme.colorPalette.surface.bright};

	${({ isMobile }) =>
		isMobile
			? ""
			: mediaQuery("md")`
		display: none;
	`}

	${mediaQuery("md")`
		position: static;
		width: 100%;
		background: none;
	`}
`
