export const attachmentDisplayName = (file: FileObject | undefined, showExtension = true) => {
	if (!file) return ""
	const {
		metadata: { filename },
		filename: originalFilename,
	} = file

	let displayName = originalFilename

	if (filename) {
		const extension = originalFilename.split(".").pop()
		displayName = `${filename}${showExtension ? `.${extension}` : ""}`
	}

	return displayName
}

export const getFileIconName = (item: string) => {
	const ext = (item.split(".").pop() ?? "").toLowerCase()

	// use ext to return file icon
	switch (ext) {
		case "pdf":
			return "pdf"
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
		case "svg":
		case "webp":
		case "avif":
			return "img"
		case "mp4":
		case "mov":
		case "avi":
		case "wmv":
		case "flv":
		case "mkv":
		case "webm":
			return "video"
		default:
			return "file"
	}
}

export const getPreviewUrl = (file: FileObject) => {
	if (file.status === null || file.status.upload !== "Complete") {
		return undefined
	}
	if (file.publicUrls.urlPreview !== undefined) {
		return file.publicUrls.urlPreview
	}

	if (file.metadata?.streaming?.thumbNailsUrls !== undefined) {
		return file.metadata.streaming.thumbNailsUrls[0]
	}

	return undefined
}
