import { FC, useEffect, useRef, Fragment, useMemo } from "react"
import styled, { useTheme } from "styled-components"
import mediaQuery from "../../../util/mediaQuery"
import { useTranslation } from "react-i18next"
import useTechPack from "../../../hooks/useTechPack"
import useIsInViewport from "../../../hooks/useIsInViewport"
import SectionTitle from "./SectionTitle"
import Linkify from "linkify-react"
import {
	getExternalGoogleMapsLink,
	getStaticGoogleMapsLink,
	getVenueLocation,
} from "@/util/locationHandling"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import useDarkMode from "@/hooks/useDarkMode"

const { REACT_APP_API_URL } = process.env

const GeneralInfo: FC = () => {
	const { t } = useTranslation()
	const generalRef = useRef(null)
	const isInViewPort = useIsInViewport(generalRef)
	const theme = useTheme()
	const { darkMode } = useDarkMode()

	const {
		venue,
		venueLogoUrl,
		stage: { name: stageName, venueStageType },
		setState,
	} = useTechPack()

	const venueLatLong = useMemo(() => (venue.id === "" ? "" : getVenueLocation(venue)), [venue])

	const { data: staticMapLink = "" } = useQuery({
		queryKey: [
			"api-static-map",
			{
				venueLatLong: venueLatLong,
				darkMode: darkMode,
			},
		],
		queryFn: async () =>
			axios
				.post<{
					data: {
						path: string
					}
				}>(`${REACT_APP_API_URL}/venues/v1/url-signing`, {
					path: getStaticGoogleMapsLink(venue, {
						darkMode: darkMode,
						markerHex: theme.techPackHighlight,
					}),
				})
				.then(res => res.data.data.path),
		enabled: venueLatLong !== "",
	})

	useEffect(() => {
		setState((s: TechPackStateInterface) => ({ ...s, showHeader: isInViewPort }))
	}, [isInViewPort, setState])

	const previousNames = venue.previousNames.map(pn => pn.name).join(", ")

	const venueWebsite = (venue?.urls ?? []).find(url => url.label === "primary")?.url || ""

	const publicNotes = ((venue?.notes ?? []).find(note => note.label === "public") || { notes: "" })
		.notes

	return (
		<MainContainer id={"venueInfo"}>
			<SectionTitle sectionName={t("venueInfo")} />
			<ContentContainer>
				<InfoContainer>
					<VenueStageName data-cy="techPack-generalInfo_VenueStageName">{`${venue.name}  —  ${stageName}`}</VenueStageName>
					<KeyValueContainer>
						<KeyContainer>
							<KeyText>{t("stageType")}</KeyText>
							<KeyValueLine />
						</KeyContainer>
						<ValueText>{venueStageType ? t(venueStageType.name) : t("none")}</ValueText>
					</KeyValueContainer>
					{venue.ageRequirement && (
						<KeyValueContainer>
							<KeyContainer>
								<KeyText>{t("ageRequirement")}</KeyText>
								<KeyValueLine />
							</KeyContainer>
							<ValueText>{venue.ageRequirement}</ValueText>
						</KeyValueContainer>
					)}
					{venueWebsite && (
						<KeyValueContainer>
							<KeyContainer>
								<KeyText>{t("venueWebsite")}</KeyText>
								<KeyValueLine />
							</KeyContainer>
							<ValueText>
								<Linkify
									options={{
										target: "_blank",
										rel: "noreferrer",
									}}
								>
									{venueWebsite}
								</Linkify>
							</ValueText>
						</KeyValueContainer>
					)}
					{previousNames && (
						<KeyValueContainer>
							<KeyContainer>
								<KeyText>{t("previousNames")}</KeyText>
								<KeyValueLine />
							</KeyContainer>
							<ValueText>{previousNames}</ValueText>
						</KeyValueContainer>
					)}
					{venue.contacts.length > 0 &&
						venue.contacts.map(contact => (
							<Fragment key={`venue_contact_${contact.label}`}>
								{contact.phone ? (
									<KeyValueContainer>
										<KeyContainer>
											<KeyText>
												{contact.label} {t("phone")}
											</KeyText>
											<KeyValueLine />
										</KeyContainer>
										<ValueText>
											<a href={`tel:${contact.phone}`}>{contact.phone}</a>
										</ValueText>
									</KeyValueContainer>
								) : null}
								{contact.email ? (
									<KeyValueContainer key={`venue_contact_${contact.label}_email`}>
										<KeyContainer>
											<KeyText>
												{contact.label} {t("email")}
											</KeyText>
											<KeyValueLine />
										</KeyContainer>
										<ValueText>
											<a href={`mailto:${contact.email}`}>{contact.email}</a>
										</ValueText>
									</KeyValueContainer>
								) : null}
							</Fragment>
						))}
					{publicNotes && (
						<PublicNotesContainer id="venueInfo-notes">
							<KeyText>{t("notes")}</KeyText>
							<PublicNotesContent>
								<Linkify
									options={{
										target: "_blank",
										rel: "noreferrer",
									}}
								>
									{publicNotes}
								</Linkify>
							</PublicNotesContent>
						</PublicNotesContainer>
					)}
				</InfoContainer>
				<StyledLogoContainer ref={generalRef}>
					{venueLogoUrl ? <ResponsiveImage src={venueLogoUrl} alt="venue" /> : null}
				</StyledLogoContainer>
				<LocationContainer>
					<StaticMapImg
						width="100%"
						height="270"
						src={staticMapLink}
						alt={"Venue Map"}
						validLink={venueLatLong !== ""}
						onClick={
							venueLatLong === ""
								? undefined
								: () => {
										window.open(getExternalGoogleMapsLink(venue), "_blank", "noreferrer")
								  }
						}
					/>
					<AddressContainer>
						<AddressText>{venue.addressLine1}</AddressText>
						<AddressText>{venue.addressLine2}</AddressText>
						<AddressText>{`${venue.city} ${venue.state} ${venue.country} ${venue.zip}`}</AddressText>
					</AddressContainer>
				</LocationContainer>
			</ContentContainer>
		</MainContainer>
	)
}

const StaticMapImg = styled("img")<{ validLink: boolean }>`
	object-fit: cover;
	margin-bottom: 15px;
	border-radius: 5px;
	cursor: ${({ validLink }) => (validLink ? "pointer" : "default")};
`

const MainContainer = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border: ${({ theme }) => `1px solid ${theme.techPackBorder}`};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 30px;
	width: 100%;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
`
const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: calc(60% - 15px) calc(40% - 15px);
	grid-column-gap: 30px;
	margin-top: 30px;

	${mediaQuery("xl")`
		display: flex;
		flex-direction: column;
	`}
`
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	grid-row: 1 / 3;
	grid-column: 1;
	order: 1;
	align-content: flex-start;
`
const StyledLogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: end;
	grid-row: 1;
	grid-column: 2;
	${mediaQuery("xl")`
		order: 0
	`};
`
const ResponsiveImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	margin-bottom: 4px;

	${mediaQuery("xl")`
		max-height: 150px;
		width: auto;
	`}
`

const VenueStageName = styled.p`
	font-size: 22px;
	font-family: "Signika-Bold" !important;
	color: ${({ theme }) => theme.techPackText};
	line-height: 26px;

	${mediaQuery("xl")`
	`}
`

const KeyValueContainer = styled.div`
	align-items: baseline;
	display: flex;
	width: 100%;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	white-space: normal;
	font-size: 14px;
	margin-top: 30px;

	${mediaQuery("lg")`
		font-size: 12px;
	`}
`
const KeyContainer = styled.div`
	display: flex;
	align-items: flex-start;
	width: 54%;
`
const KeyText = styled.p`
	font-family: "Signika-Bold" !important;
	color: ${({ theme }) => theme.techPackText};
	font-size: 18px;
	line-height: 21px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 5px;
	word-break: break-word;
	white-space: nowrap;
	min-width: 140px;

	${mediaQuery("lg")`
		font-size: 15px;
	`}
	${mediaQuery("xl")`
		width: 100%;
	`}
`
const KeyValueLine = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.techPackText};
	flex-grow: 1;
	margin-left: 15px;
	height: 16px;
	width: 100%;
`
const ValueText = styled.p`
	font-family: "Signika-Regular" !important;
	color: ${({ theme }) => theme.techPackText};
	font-size: 18px;
	margin: 0;
	width: 50%;
	margin-left: 15px;

	& > a {
		color: ${({ theme }) => theme.colorPalette.primary.value};
		font-size: 18px;

		${mediaQuery("lg")`
			font-size: 13px;
		`}
	}

	${mediaQuery("lg")`
		font-size: 13px;
	`}

	${mediaQuery("xl")`
		text-align: center;
		width: 100%;
		text-align: left;
	`}
`
const PublicNotesContainer = styled.div`
	word-break: break-word;
	margin-bottom: 30px;
	margin-top: 30px;
	white-space: pre-wrap;

	${mediaQuery("lg")`
		font-size: 12px;
	`}
`

const PublicNotesContent = styled.p`
	font-family: "Signika-Regular" !important;
	color: ${({ theme }) => theme.techPackText};
	font-size: 18px;
	line-height: 24px;
	white-space: pre-wrap;
	margin: 0;
	margin-top: 15px;
	text-align: left;

	${mediaQuery("lg")`
		font-size: 13px;
		line-height: 15px;
	`}

	a {
		color: ${({ theme }) => theme.colorPalette.primary.value};
	}
`
const LocationContainer = styled.div`
	border-top: 1px solid ${({ theme }) => theme.techPackText};
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-row: 2;
	grid-column: 2;

	${mediaQuery("xl")`
		order: 2
	`}
`
const AddressContainer = styled.div`
	align-self: start;
`
const AddressText = styled.p`
	margin: 0;

	${mediaQuery("lg")`
		font-size: 16px;
		line-height: 20px;
	`}
`

export default GeneralInfo
