import { normalizeTimezone } from "./dateFunctions"

const primaryCategoryOverrides: Record<string, string> = {
	productionData: "production",
	facilitiesData: "facilities",
	equipmentData: "equipment",
	logisticsData: "logistics",
	localCrewData: "localCrew",
}

const venueStagePageKeys = [
	"isMainStage",
	"capacity",
	"name",
	"venueId",
	"venueStageTypeId",
	"id",
	"isActive",
	"slug",
]

export const getFilterFieldSupportText = (field: HistoryField) => {
	const labelName = field.name
	const pageName =
		field.objectType === "venueStage"
			? venueStagePageKeys.includes(labelName)
				? "venueStage"
				: "production"
			: field.objectType === "venue"
			? "production"
			: field.objectType
	if (pageName !== "production") {
		return [pageName]
	}

	if (field.objectType === "venue") {
		return [pageName, "generalVenueInfo"]
	}

	const splitCategory = labelName.split(".")

	if (splitCategory.length < 2) {
		return [pageName]
	}

	return [pageName, primaryCategoryOverrides[splitCategory[0]] || splitCategory[0]]
}

export const getUserName = (user: HistoryUser) => {
	const fullName = [user.name.nickName || user.name.firstName || "", user.name.lastName || ""]
		.filter(val => val !== "")
		.join(" ")
	if (fullName === "" || fullName === " ") {
		return user.name.displayName || ""
	}

	return fullName
}

export const getActionType = (dataLog: HistoryDataChangeLog) => {
	if (dataLog.originalValue === null || dataLog.originalValue === "null") {
		return "added"
	}
	if (dataLog.updatedValue === null || dataLog.updatedValue === "null") {
		return "deleted"
	}

	return "changed"
}

export const getHistoryTimestamp = (dateString: string, separator: string = ", ") => {
	const parsedDate = new Date(normalizeTimezone(dateString.replace(/-/g, "/")))

	const formattedDate = new Intl.DateTimeFormat("en-US", {
		month: "numeric",
		day: "numeric",
		year: "2-digit",
	}).format(parsedDate)

	const formattedTime = new Intl.DateTimeFormat("en-US", {
		hour12: true,
		hour: "numeric",
		minute: "2-digit",
	}).format(parsedDate)

	return `${formattedDate}${separator}${formattedTime}`
}

export const normalizeStringBoolean = (value: string) => {
	if (value === "0" || value === "false") {
		return "false"
	}

	return "true"
}
