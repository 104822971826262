import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

// note, this uses axios and not useAxios
// unauthenticated for tech-pack

type DownloadParams = {
	url: string
	filename: string
}

export default function useDownloadAttachment() {
	const { t } = useTranslation()

	const { mutate: downloadAttachment, isPending: isDownloadLoading } = useMutation({
		mutationKey: ["download-attachment"],
		mutationFn: async ({ url, filename }: DownloadParams) => {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					var reader = new FileReader()
					reader.onload = function () {
						var a = document.createElement("a")
						const fileDownloadUrl = window.URL.createObjectURL(blob)
						a.href = fileDownloadUrl
						a.download = filename
						a.click()
						setTimeout(() => {
							URL.revokeObjectURL(fileDownloadUrl)
						}, 3000)
					}
					reader.readAsDataURL(blob)
				})
		},
		meta: {
			errorMessage: t("errorTryAgain"),
		},
	})

	return { downloadAttachment, isDownloadLoading }
}
