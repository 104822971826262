import { Box, Divider, Unstable_Grid2 as Grid } from "@mui/material"
import Permalink from "../../molecules/Permalink"
import { FormEventHandler, useCallback, useEffect, useMemo, useState } from "react"
import styled, { css, useTheme } from "styled-components"
import FormField from "../../atoms/FormField"
import { Select } from "../../molecules/Select"
import Button from "../../atoms/NewButton"
import {
	CancelOutlined,
	Check,
	Star,
	VisibilityOffOutlined,
	VisibilityOutlined,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { DeactivateStageModal } from "./DeactivateStageModal"
import { useVenueStageTypes } from "../../../hooks/useVenueStageTypes"
import { useVenuesContext } from "@/context/venues"

interface VenueStageEditData {
	stageId: string
	slug: string
	stageCapacity: string
	stageType: string
	stageName: string
}

const useVenueStageEditData = (initialState: {
	stageId: string
	slug: string
	stageCapacity: number
	venueStageTypeId: string
	stageName: string
}): [
	VenueStageEditData,
	(venueKey: keyof VenueStageEditData, value: VenueStageEditData[keyof VenueStageEditData]) => void,
	boolean,
	FormEventHandler,
] => {
	const { venueStageTypes } = useVenueStageTypes()
	const { patchStage } = useVenuesContext()

	const [stageData, setStageData] = useState<VenueStageEditData>({
		stageId: "",
		slug: "",
		stageCapacity: "",
		stageType: "",
		stageName: "",
	})

	const parsedInitialState = useMemo(() => {
		return {
			stageId: initialState.stageId,
			slug: initialState.slug,
			stageCapacity: initialState.stageCapacity.toString(),
			stageType:
				venueStageTypes.find(val => val.id === initialState.venueStageTypeId)?.translatedValue ||
				"",
			stageName: initialState.stageName,
		}
	}, [initialState, venueStageTypes])

	const canSubmit = useMemo(
		() =>
			parsedInitialState.stageType !== stageData.stageType ||
			parsedInitialState.stageName !== stageData.stageName ||
			parsedInitialState.stageCapacity !== stageData.stageCapacity,
		[
			parsedInitialState.stageType,
			parsedInitialState.stageName,
			parsedInitialState.stageCapacity,
			stageData.stageCapacity,
			stageData.stageName,
			stageData.stageType,
		],
	)

	const updateStageData = useCallback(
		(venueKey: keyof VenueStageEditData, value: VenueStageEditData[keyof VenueStageEditData]) => {
			setStageData(curr => ({
				...curr,
				[venueKey]: value,
			}))
		},
		[setStageData],
	)

	const handleSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault()

			if (!canSubmit) {
				return
			}

			const foundVenueStageTypeId = venueStageTypes.find(
				val => val.translatedValue === stageData.stageType,
			)?.id
			const parsedVenueStageData = Number.parseInt(stageData.stageCapacity)

			const uploadPackage: Partial<Pick<Stage, "name" | "capacity" | "venueStageTypeId">> = {
				name: initialState.stageName !== stageData.stageName ? stageData.stageName : undefined,
				capacity:
					!Number.isNaN(parsedVenueStageData) && initialState.stageCapacity !== parsedVenueStageData
						? parsedVenueStageData
						: undefined,
				venueStageTypeId:
					foundVenueStageTypeId !== initialState.venueStageTypeId
						? foundVenueStageTypeId
						: undefined,
			}

			patchStage(
				{
					stageId: initialState.stageId,
					updateData: uploadPackage,
				},
				{
					onError: () => {
						setStageData(parsedInitialState)
					},
				},
			)
		},
		[
			canSubmit,
			venueStageTypes,
			stageData.stageCapacity,
			stageData.stageName,
			stageData.stageType,
			initialState.stageName,
			initialState.stageCapacity,
			initialState.venueStageTypeId,
			initialState.stageId,
			patchStage,
			parsedInitialState,
		],
	)

	useEffect(() => {
		if (parsedInitialState.stageId !== stageData.stageId) {
			setStageData(parsedInitialState)
		} else if (parsedInitialState.stageType !== "" && stageData.stageType === "") {
			setStageData(curr => ({
				...curr,
				stageType: parsedInitialState.stageType,
			}))
		}
	}, [parsedInitialState, stageData.stageId, stageData.stageType, setStageData])

	return [stageData, updateStageData, canSubmit, handleSubmit]
}

interface VenueStageDetailProps
	extends Pick<
		Stage,
		"venueId" | "isMainStage" | "name" | "capacity" | "venueStageTypeId" | "isActive"
	> {
	stageId: string
	techPackUrl: string
	/** Override Venue Stage */
	hideDivider?: boolean
	stageHasChanges: Record<string, boolean>
	setStageHasChanges: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
}

export const VenueStageDetail: React.FC<VenueStageDetailProps> = props => {
	const { t } = useTranslation()
	const { colorPalette } = useTheme()
	const { venueStageTypes } = useVenueStageTypes()
	const { patchStage } = useVenuesContext()

	const { stageHasChanges, setStageHasChanges, stageId, techPackUrl, ...rest } = props

	const [editableUrl, baseUrl] = useMemo(() => {
		const splitUrl = techPackUrl.split("/")
		return [splitUrl.pop(), splitUrl.join("/")]
	}, [techPackUrl])

	const [venueStageData, updateVenueStageData, canSubmit, handleSubmit] = useVenueStageEditData({
		stageId: stageId,
		slug: editableUrl || "",
		stageCapacity: rest.capacity,
		venueStageTypeId: rest.venueStageTypeId,
		stageName: rest.name,
	})

	const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)

	const handlePermalinkUpdate = useCallback(
		(newSlug: string) => {
			const originalSlug = venueStageData.slug
			updateVenueStageData("slug", newSlug)
			patchStage(
				{
					stageId: stageId,
					updateData: {
						slug: newSlug,
					},
				},
				{
					onError: () => {
						updateVenueStageData("slug", originalSlug)
					},
				},
			)
		},
		[updateVenueStageData, patchStage, venueStageData.slug, stageId],
	)

	const handleActivateStage = useCallback(() => {
		patchStage({
			stageId: stageId,
			updateData: {
				isActive: 1,
			},
		})
	}, [stageId, patchStage])

	const handleSetMainStage = useCallback(() => {
		patchStage({
			stageId: stageId,
			updateData: {
				isMainStage: 1,
			},
		})
	}, [stageId, patchStage])

	const hasInvalidField = useMemo(
		() =>
			venueStageData.stageName.replaceAll(" ", "") === "" ||
			venueStageData.stageCapacity.replaceAll(" ", "") === "" ||
			venueStageData.stageType.replaceAll(" ", "") === "",
		[venueStageData.stageName, venueStageData.stageCapacity, venueStageData.stageType],
	)

	useEffect(() => {
		if (stageHasChanges[stageId] === undefined) {
			setStageHasChanges(prev => ({
				...prev,
				[stageId]: false,
			}))
		} else if (!canSubmit !== stageHasChanges[stageId]) {
			setStageHasChanges(prev => ({
				...prev,
				[stageId]: !canSubmit,
			}))
		}
	}, [setStageHasChanges, stageId, stageHasChanges, canSubmit])

	return (
		<RootGrid container gap="24px">
			{!rest.isMainStage && !rest.hideDivider && (
				<Grid xs={12} padding="28px 0px">
					<Divider />
				</Grid>
			)}
			<ScrollGrid container xs={12} gap="32px" minWidth="875px">
				<Grid container xs={12} gap="8px" alignItems="space-between">
					<Grid xs={12}>
						<Box display="flex" alignItems="center" gap="4px">
							{rest.isMainStage && <Star />}
							{!rest.isActive && <VisibilityOffOutlined />}
							<TitleText>{rest.name}</TitleText>
						</Box>
					</Grid>
					<Grid xs={12}>
						<Permalink
							baseLink={baseUrl}
							editText={venueStageData.slug}
							setEditText={handlePermalinkUpdate}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					xs={12}
					columnSpacing={0.25}
					justifyContent="space-between"
					width="fitContent"
				>
					<Grid minWidth="180px" container xs={2.25} rowSpacing="16px" alignContent="start">
						{(!rest.isMainStage || !rest.isActive) && (
							<Grid xs={12}>
								{!rest.isActive ? (
									<Button
										buttonType="Outline"
										text={t("activateStage")}
										borderColor="secondary"
										iconElement={<VisibilityOutlined />}
										onClick={handleActivateStage}
									/>
								) : (
									<Button
										buttonType="Outline"
										text={t("setAsMainStage")}
										borderColor="secondary"
										iconElement={<Star />}
										onClick={() => handleSetMainStage()}
									/>
								)}
							</Grid>
						)}
						<Grid xs={12}>
							<MainStageHint>
								{rest.isMainStage
									? t("currentlyMainStage")
									: rest.isActive
									? t("willReplaceMainStage")
									: t("activateStageNotice")}
							</MainStageHint>
						</Grid>

						{!rest.isMainStage && rest.isActive && (
							<Grid xs={12}>
								<Button
									buttonType="Text"
									text={t("deactivateStage")}
									iconElement={
										<VisibilityOffOutlined htmlColor={colorPalette.error.container.on} />
									}
									onClick={() => setDeactivateModalOpen(true)}
									textStyles={css`
										color: ${({ theme }) => theme.colorPalette.error.container.on};
									`}
								/>
							</Grid>
						)}
					</Grid>
					<Grid
						container
						xs={9.25}
						spacing="24px"
						component="form"
						id={`${stageId}-form`}
						onSubmit={handleSubmit}
						minWidth="625px"
					>
						<Grid xs={12}>
							<FormField
								label={`*${t("stageName")}`}
								value={venueStageData.stageName}
								onChange={e => {
									updateVenueStageData("stageName", e.target.value)
								}}
								fullWidth
								InputProps={{
									endAdornment: (
										<StyledClearTextIcon
											aria-hidden={venueStageData.stageName === ""}
											onClick={() => updateVenueStageData("stageName", "")}
										/>
									),
								}}
							/>
						</Grid>
						<Grid xs={6}>
							<FormField
								label={`${t("maxCapacity")}*`}
								fullWidth
								type="number"
								value={venueStageData.stageCapacity}
								onChange={e => updateVenueStageData("stageCapacity", e.target.value)}
								InputProps={{
									endAdornment: (
										<StyledClearTextIcon
											aria-hidden={venueStageData.stageCapacity === ""}
											onClick={() => updateVenueStageData("stageCapacity", "")}
										/>
									),
								}}
							/>
						</Grid>
						<Grid xs={6}>
							<Select
								value={venueStageData.stageType}
								onChange={e => updateVenueStageData("stageType", e.target.value)}
								optionValues={venueStageTypes.map(val => val.translatedValue)}
								fullWidth
								label={`${t("stageType")}*`}
								MenuProps={{
									sx: {
										"& .MuiPaper-root": {
											maxHeight: "200px",
										},
									},
								}}
							/>
						</Grid>
						<Grid xs={12}>
							<Button
								buttonType="Outline"
								borderColor="primary"
								text={t("update")}
								iconElement={<Check />}
								buttonStyles={css`
									width: 100%;
								`}
								form={`${stageId}-form`}
								type="submit"
								disabled={!canSubmit || hasInvalidField}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ScrollGrid>
			<DeactivateStageModal
				deactivateStageId={stageId}
				modalOpen={deactivateModalOpen}
				closeModal={() => setDeactivateModalOpen(false)}
			/>
		</RootGrid>
	)
}

const ScrollGrid = styled(Grid)`
	height: auto;
`

const StyledClearTextIcon = styled(CancelOutlined)`
	color: ${({ theme }) => theme.colorPalette.surface.onVariant};
	cursor: pointer;

	opacity: 1;
	visibility: visible;

	&[aria-hidden="true"] {
		opacity: 0;
		visibility: hidden;
	}

	transition: visibility 150ms ease-out, opacity 150ms ease-out;
`

const RootGrid = styled(Grid)`
	width: 100%;

	& input::-webkit-outer-spin-button,
	& input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	& input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}
`

const TitleText = styled("h6")`
	font: 400 24px/32px "Roboto-Regular", sans-serif;
	text-align: left;
	color: ${({ theme }) => theme.colorPalette.surface.on};
	gap: 4px;
`

const MainStageHint = styled("p")`
	&::before {
		content: "*";
	}

	color: ${({ theme }) => theme.colorPalette.secondary.value};
	font: 500 11px/16px "Signika-Medium";
	text-align: left;
`
