import NewModal from "../../molecules/NewModal"
import Add from "../../../icons/Add"
import { CategoryOutlined } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import styled, { css, useTheme } from "styled-components"
import FormField from "../../atoms/FormField"
import FormControlLabel from "../../atoms/FormControlLabel"
import Checkbox from "../../atoms/Checkbox"
import { Select } from "../../molecules/Select"
import React, { FC, useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useVenueStageTypes } from "../../../hooks/useVenueStageTypes"
import { toast } from "react-toastify"
import { useVenuesContext } from "@/context/venues"

interface AddStageModalProps {
	modalOpen: boolean
	closeModal: () => void
	selectedVenueId: string | null
}

export const AddStageModal: FC<AddStageModalProps> = ({
	modalOpen,
	closeModal,
	selectedVenueId,
}) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const { venueStageTypes } = useVenueStageTypes()
	const { addStage } = useVenuesContext()

	const [stageName, setStageName] = useState("")
	const [maxCapacity, setMaxCapacity] = useState("")
	const [stageType, setStageType] = useState("")
	const [isMainStage, setIsMainStage] = useState(false)
	const [contentInvalid, setContentInvalid] = useState(false)

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (selectedVenueId === null) {
			toast.error(t("errorTryAgain"))
		}

		addStage(
			{
				venueId: selectedVenueId,
				name: stageName,
				isMainStage: isMainStage,
				capacity: maxCapacity,
				venueStageTypeId: venueStageTypes.find(val => val.translatedValue === stageType)?.id,
			},
			{
				onSuccess: () => {
					closeModal()
				},
			},
		)
	}

	useEffect(() => {
		if (!modalOpen) {
			setStageName("")
			setMaxCapacity("")
			setStageType("")
			setContentInvalid(false)
		}
	}, [modalOpen])

	return (
		<NewModal
			size="sm"
			open={modalOpen}
			closeModal={closeModal}
			titleIcon={<CategoryOutlined fontSize="inherit" />}
			title={t("addNewStage")}
			sx={{
				"& small::before": {
					content: `"*"`,
					color: theme => (contentInvalid ? theme.colorPalette.error.value : "inherit"),
				},
			}}
			hint={
				<Box
					component="span"
					color={theme => (contentInvalid ? theme.colorPalette.error.value : "inherit")}
				>
					{t("requiredField_plural")}
				</Box>
			}
			footerActions={{
				end: [
					"Cancel",
					{
						iconElement: <Add color={theme.colorPalette.primary.value} size={18} />,
						text: t("addStage"),
						buttonType: "Outline",
						stateLayerStyles: css`
							width: 116px;
							padding: 10px 16px 10px 12px;
						`,
						id: "testing-ids",
						form: "add-stage-form",
						type: "submit",
					},
				],
			}}
		>
			<StyledBox
				component="form"
				id="add-stage-form"
				onSubmit={handleSubmit}
				onInvalid={() => setContentInvalid(true)}
			>
				<FormField
					label={t("stageName")}
					value={stageName}
					onChange={e => setStageName(e.target.value)}
					type="text"
					required
					fullWidth
					error={stageName === "" && contentInvalid}
				/>
				<FormField
					label={`${t("maxCapacity")}`}
					value={maxCapacity}
					onChange={e => setMaxCapacity(e.target.value)}
					type="number"
					required
					fullWidth
					error={maxCapacity === "" && contentInvalid}
				/>
				<Select
					title="stage-type-select"
					label={`${t("stageType")}`}
					value={stageType}
					onChange={e => setStageType(e.target.value)}
					required
					simple
					fullWidth
					optionValues={venueStageTypes.map(val => val.translatedValue)}
					MenuProps={{
						sx: {
							"& .MuiPaper-root": {
								maxHeight: "267px",
							},
							transform: "translate(0px, -106px)",
							transition: "transform 150ms ease-out",
						},
					}}
					error={stageType === "" && contentInvalid}
				/>
				<FormControlLabel
					label={`${t("setAsMainStage")}`}
					control={
						<Checkbox checked={isMainStage} onChange={e => setIsMainStage(e.target.checked)} />
					}
				/>
			</StyledBox>
		</NewModal>
	)
}

const StyledBox = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;

	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	gap: 16px;

	& input::-webkit-outer-spin-button,
	& input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	& input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	& .MuiFormControl-root[title="stage-type-select"]:has(.MuiSelect-select[aria-expanded="true"]) {
		height: 267px;
	}

	& .MuiFormControl-root[title="stage-type-select"] {
		transition: height 150ms ease-out;
	}
`

export default AddStageModal
