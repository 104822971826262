import React from "react"
import { styled } from "@mui/material"
import SingleLineLight from "@logos/MTV_Logo_Black.svg"
import SingleLineDark from "@logos/MTV_Logo_White.svg"
import useDarkMode from "@/hooks/useDarkMode"

export const LoginWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { darkMode } = useDarkMode()

	return (
		<PageWrapper>
			<FormContainer>
				<StyledImg src={darkMode ? SingleLineDark : SingleLineLight} alt="Eventric Logo" />

				{children}
			</FormContainer>
		</PageWrapper>
	)
}

const StyledImg = styled("img")`
	width: 100%;
	margin-bottom: 40px;
`

const FormContainer = styled("div")`
	width: 448px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	justify-content: start;
	flex-direction: column;

	align-items: center;
	gap: 40px;
`

const PageWrapper = styled("div")`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	background: ${({ theme }) => theme.colorPalette.surface.bright};
	overflow-y: scroll;
`
